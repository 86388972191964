import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/index.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/trans',
    name: 'Trans',
    component: () => import('@/views/trans/list.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/trans/del',
    name: 'TransDel',
    component: () => import('@/views/trans/del.vue'),
    meta:{
      headshow:true,
      footshow:true
    }

  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('@/views/notice/list.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/notice/del',
    name: 'NoticeDel',
    component: () => import('@/views/notice/del.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/operation',
    name: 'Operation',
    component: () => import('@/views/operation/index.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/help/list.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index.vue'),
    meta:{
      headshow:false,
      footshow:false
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download/index.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/cahandl',
    name: 'Cahandl',
    component: () => import('@/views/cahandl/index.vue'),
    meta:{
      headshow:true,
      footshow:true
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service/index.vue'),
    meta:{
      headshow:false,
      footshow:false,
      serviceshow:false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
   // 指定该钩子函数，返回坐标值即可
   scrollBehavior () {
    return { x: 0, y: 0 }
   }
})


export default router
