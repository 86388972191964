<template>
  <div class="conr">
    <div class="login" @click="loginHref(cominfo.zbrLink)">
      <img class="bg" src="@assets/img/login0.png" alt="" />
      <div class="btw user">
        <h3>招标人登录</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div>
    <div class="login" @click="loginHref(cominfo.agentLink)">
      <img class="bg" src="@assets/img/login1.jpg" alt="" />
      <div class="btw user">
        <h3>代理机构登录</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div>
    <div class="login" @click="loginHref(cominfo.tenderLink)">
      <img class="bg" src="@assets/img/login2.jpg" alt="" />
      <div class="btw user">
        <h3>投标人登录</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div>
     <!-- <div class="login" @click="loginHref(cominfo.jdLink)">
      <img class="bg" src="@assets/img/login6.png" alt="" />
      <div class="btw user">
        <h3>监督通道</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div> -->
    <div class="login" @click="loginHref(cominfo.reviewLink)">
      <img class="bg" src="@assets/img/login3.jpg" alt="" />
      <div class="btw user">
        <h3>评审专家登录</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div>
    <div class="login kb">
      <img class="bg" src="@assets/img/login4.jpg" alt="" />
      <div class="btw user">
        <h3>在线开标大厅入口</h3>
      </div>
      <div class="kbbtn btw">
        <div class="btw kbitem" @click="loginHref(cominfo.bidagentLink)">
          <h3>投标人</h3>
          <img class="enter" src="@assets/img/enter.png" alt="" />
        </div>
        <div class="btw kbitem" @click="loginHref(cominfo.bidtbrLink)">
          <h3>招标代理</h3>
          <img class="enter" src="@assets/img/enter.png" alt="" />
        </div>
      </div>
    </div>
    <div class="login newuser" @click="loginHref(0)">
      <div class="btw">
        <h3>新用户注册</h3>
        <img class="enter" src="@assets/img/enter.png" alt="" />
      </div>
    </div>
    <div class="conr-cen box-bg top15">
      <div class="tit pointer" @click="getmore">常用下载
        <span>>>更多</span>
      </div>
      <div class="download">
        <div class="zxload-item row" v-for="item in download" :key="item.infoid" @click="getdown(item.urlname)">
          <img src="@assets/img/download_icon.png" />
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
    <!-- <div class="conr-bot box-bg top15">
      <div class="tit">常用工具</div>
      <div class="service">
        <div class="service-item" v-for="item in 6" :key="item">场地安排</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { tooldownload } from "@/api/index.js";
export default {
  name: "conr",
  props:['cominfo'],
  data() {
    return {
      download:[]

    };
  },
  mounted () {
    this.gettooldownload()
  },
  methods: {
      gettooldownload() {
        tooldownload().then((data) => {
          this.download = data.tdata
         
        });
    },
    getdown(val){
      window.open(val,'')
    },
    loginHref(val) {
      if(val==0){
          this.$router.push({
            path: this.com.loginurl[val].url,
          });
      }else{
        window.open(val,'_blank')
      }
     
    },
    getmore(){
      this.$router.push({
          path:'/download' ,
        });
    }
  },
};
</script>

<style  lang="less"  scoped>
.conr {
  width: 330px;
}
.tit{
  position: relative;
  font-size: 16px;
  color: #333333;
  padding-left: 15px;
  text-align: left;
   margin: 5px 0;
   font-weight: bold;
   span{
     color: #999;
     font-size: 13px;
     float: right;
   }
   &:hover{
     span{
       color: @bule;
     }
   }
}
.tit.pointer{
  cursor: pointer;
}
.tit::before{
  content: '';
  background: @bule;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
 
}
.login {
  cursor: pointer;
  margin-bottom: 14px;
  position: relative;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 2px 5px rgb(92, 92, 92);
  }
  .user {
    position: absolute;
    z-index: 1;
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 80px;
    top: 0;
    left: 0;
    h3 {
      height: 80px;
      line-height: 80px;
      color: #fff;
    }
  }
  .kbbtn {
    position: absolute;
    z-index: 1;
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 80px;
    bottom: 0;
    left: 0;
    .kbitem {
      background:@kbbtn-bg;
      border-radius: 5px;
      height: 40px;
      padding: 0 20px;
      width: 94px;
      cursor: pointer;
      &:hover {
        background: @kbbtn-bg-hover;
        box-shadow: 0 5px 5px rgb(41, 41, 41);
      }
      h3 {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
      .enter {
        width: 20px;
      }
    }
  }
}
.login.kb {
  &:hover {
    opacity: 1;
    box-shadow: none;
  }
}
.newuser {
  background:@newuser-bg;
  padding: 0 20px;
  h3 {
    height: 80px;
    line-height: 80px;
    color: #fff;
  }
}
.zxtit {
  font-size: 17px;
  line-height: 50px;
}
.zxload-item {
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}
.zxload-item img {
  width: 10px;
  margin-right: 6px;
}
.zxload-item:hover span {
  color: @bule;
}
::v-deep .el-button.newuser {
  margin-top: 10px;
  line-height: 20px;
  font-size: 15px;
  z-index: 1;
}
.conr-cen {
  padding-bottom: 22px;
  background: #fff url("../../../assets/img/right02.png") no-repeat right bottom;
}
.download {
  padding: 5px 20px;
}
.conr-bot {
  overflow: hidden;
  padding-bottom: 20px;
  background: #fff url("../../../assets/img/right03.png") no-repeat right bottom;
}
.service-item {
  background: @down-bg;
  color: #333;
  height: 50px;
  border-radius: 5px;
  width: 106px;
  float: left;
  margin: 7px;
  font-size: 14px;
  cursor: pointer;
  text-align: right;
  line-height: 50px;
  padding: 0 10px;
}
.service-item:hover {
  background: @down-bg-hover;
}
</style>