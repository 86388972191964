<template>
  <div class="news box-bg">
    <div class="tit btw">
      <div class="l">
        <span
          :class="titon == item.code ? 'on' : ''"
          v-for="item in tits"
          :key="item.code"
          @click="active(item.code,item.type)"
        >
          {{ item.name }}
        </span>
      </div>
      <el-form :inline="true" :model="formInline" class="sou" label-width="100" v-if="titon!=10">
        <el-form-item label="">
          <el-input
            v-model="formInline.user"
            placeholder="请输入"
            clearable
            @clear='onSubmit'
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="news-con" v-loading="loading" v-if="titon==10">
      <div class="list btw" v-for="(item, index) in list" :key="index" @click="bussdel(item)">
        <div class="list-con">
          <div class="row">
            <div class="title ellipsis-1">
              <!--{{item.platform}}-->  {{item.title}}
            </div>
            <div class="time" >【公告发布日期】</div>
            <div class="time" v-if="titon!=5 && titon!=6">【公告结束日期】</div>

          </div>
          <div class="list-mid row">
            <div class="con row">
              <div class="zbr ellipsis-1">
                行业：{{item.industryName}}
              </div>
              <div class="icon-dz">{{item.cityName}}-{{item.areaName}}</div>
            </div>
            <div class="dtime" >{{item.publishTime}}</div>
            <div class="dtime" v-if="titon!=5 && titon!=6">{{item.publishTimeEnd}}</div>
          </div>
        </div>
        <div class="ckxq" >查看详情</div>
      </div>
       <div class="zanwu" v-if="list&&list.length==0 ">
        暂无数据
      </div>
    </div>
    <div class="news-con" v-loading="loading" v-else>
      <div class="list btw" v-for="(item, index) in list" :key="index" @click="del(item.GGCODE,item.GGTYPE)">
        <div class="list-con">
          <div class="row">
            <div class="title ellipsis-1">
             [ {{item.TYPENAME}} ] {{item.GGNAME}}
            </div>
            <div class="time" >【公告发布日期】</div>
            <div class="time" v-if="titon!=5 && titon!=6">【公告结束日期】</div>

          </div>
          <div class="list-mid row">
            <div class="con row">
              <div class="zbr ellipsis-1">
                招标方式 ：{{item.LEIXING}} -  {{item.HYNAME}}
              </div>
               <!-- <div class="zbr ellipsis-1">
                行业：{{item.HYNAME}}
              </div> -->
              <!-- <div class="icon-zb ">{{item.TYPENAME}}</div> -->
              <div class="icon-dz">{{item.CITYNAME}}</div>
            </div>
            <div class="dtime" >{{item.GGFBTIME}}</div>
            <div class="dtime" v-if="titon!=5 && titon!=6">{{item.GGENDTIME}}</div>
          </div>
        </div>
        <div class="ckxq" >查看详情</div>
      </div>
       <div class="zanwu" v-if="list&&list.length==0 ">
        暂无数据
      </div>
    </div>
    <!-- 分页 -->
     <el-pagination
      v-if="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="10"
      layout="total,  prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "news",
  props:['pagination','list','total','loading'],
  data() {
    return {
      titon: '10',
      tits: [
          { name: '全网', type: 'buss', code: '10' },
          { name: '全部', type: 'all', code: '0' },
          { name: '招标公告', type: 'zbgg', code: '1' },
          { name: '变更公告', type: 'bggg', code: '2' },
          { name: '补遗答疑', type: 'bydy', code: '3' },
          { name: '中标候选人公示', type: 'hxrgs', code: '4' },
          { name: '中标公告', type: 'zhongbiaogg', code: '5' },
          { name: '异常公告', type: 'ycgg', code: '6' },
      ],
      formInline: {
        user: "",
      },
      currentPage: 1
    };
  },
  methods: {
    active(val,type) {
      this.titon = val;
      this.$emit('type',type)
    },
    // 搜索
    onSubmit() {
      console.log(this.formInline, "submit!");
      this.$emit('sou',this.formInline.user)
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$emit('page',val)
    },
    // 详情
    del(val,type){
      this.$router.push({
          path: '/trans/del',
          query: {
            id: val,
            type:type
          }
        });
    },
    bussdel(val){
      let con = {
        title:` ${val.title}`,//${val.platform}
        categoryname:val.platform,
        infocontent:val.content
      }
      sessionStorage.setItem('busscontent',JSON.stringify(con))
      this.$router.push({
        path: "/notice/del",
        query: {
          id:'buss'
        },
        });
    }
  },
};
</script>

<style lang="less" scoped>
.news.box-bg {
  padding: 0;
}
.news-con {
  padding: 5px 25px 6px;
  min-height: 600px;
}
.tit {
  background: @bule;
  border-bottom: 1px solid @border-color;
  text-align: left;
  padding: 0 2px;
}
.tit span {
  color: #ffffff;
  font-size: 14px;
  line-height: 55px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
}
.tit span.on {
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tit span.on::before {
  content: "";
  background: @bule;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 1px;
}
.sou {
  width: 230px;
  .el-input {
    width: 160px;
    ::v-deep .el-input__inner {
      line-height: 30px;
      height: 30px;
      border-radius: 0;
      border: none;
    }
  }
  ::v-deep .el-form-item__content,
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  .el-button {
    width: 70px;
    padding: 0;
    line-height: 30px;
    border-radius: 0;
    border: none;
    background:@sou-color;
    &:hover {
      background:@sou-color-hover;
    }
  }

  .el-form-item {
    margin: 0;
  }
}
.list:last-child{
  border-bottom: none;
}
.list {
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid @border-color;
  padding-bottom: 10px;
  .list-con{
  .title {
      font-size: 15px;
      font-weight: bold;
      color: #010101;
      width: 450px;
    text-align: left;
    }
    &:hover {
      .title {
        color: @bule;
      }
    }
  }

  .time {
    color: #919191;
    width: 125px;
    text-align: center;
  }
  .dtime {
    color: #010101;
    width: 125px;
    text-align: center;
  }
  .con{
    width: 450px;
    color: #919191;
  }
  .zbr{
    width: 265px;
    text-align: left;
  }
  .list-mid{
    padding-top: 8px;
    font-size: 13px;
    .icon-zb{
      background:url('../../../assets/img/filetype.png') no-repeat left center;
      padding-left: 15px;
      margin-right: 20px;
    }
     .icon-dz{
      margin-left: 10px;
      background:url('../../../assets/img/location.png') no-repeat left center;
      padding-left: 22px;
    }
  }
  .ckxq{
    color: @bule;
    border: 1px solid @bule;
    padding: 0px 10px;
    line-height: 27px;
    border-radius: 5px;
    &:hover{
      background: @bule;
      color: #ffffff;
    }
  }
}

.more {
  text-align: right;
  color: @bule;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  margin-top: 10px;
}
.el-pagination{
  padding: 50px 0 30px;
  text-align: center;
}
</style>
