<template>
  <div>
    <div class="vid" @click="dialogVisible = true">
      <img src="@/assets/img/video.png" alt="" srcset="" />
    </div>

    <el-dialog title="播放视频" :visible.sync="dialogVisible" width="740px">
      <video
        width="700"
        height="400"
        controls
        autoplay="autoplay"
        v-if="dialogVisible"
      >
        <source src="@/assets/video/v1.mp4" type="video/mp4" />
      </video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
export default {
  name: "VideoComponent",
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {},
};
</script>
<style  lang="less"  scoped>
.vid {
  position: fixed;
  left: 10px;
  top: 50%;
  z-index: 1000;
  margin-top: -30px;
  height: 60px;
  img {
    width: 150px;
    height: 270px;
  }
}
</style>

