<template>
  <div class="ban">
    <el-carousel @change='changeban' :interval="5000" >
    <el-carousel-item v-for="(item,index) in tdata" :key="index"  >
      <div :class="banon?'title animated fadeInRight':'title animated fadeOutLeft' ">
        <h4>{{item.adtitle}}</h4>
      </div>
       <img :src="item.linkimg"  @click="open(item.linkurl)">
    </el-carousel-item>
  </el-carousel>
  </div>
</template>

<script>
import { getcarouselpic } from "@/api/index.js";
export default {
 
  name: 'Ban',
  data() {
    return {
      tdata:[],
      banon:true
    };
  },
 
  mounted() {
   this.getban()
  },
  methods: {
    changeban(){
      this.banon = false
      setTimeout(()=>{
          this.banon = true
      },600)
    },
     getban() {
      getcarouselpic({}).then((data) => {
        this.tdata = data.tdata.map((val)=>{
          let json = val
          if(val.linkimg){
           json.linkimg = val.linkimg.split(':')[0]=='http'?val.linkimg:this.com.banurl+val.linkimg
          }
         
          return json 
        })
      });
    },
    open(val){
       window.open(val,'_blank')
    }
    
  },
};
</script>
<style lang="less" scoped>
.ban{
  background:@ban-bg;
  text-align: center;
  .title{
    width: 1200px;
    position: absolute;
    left:50%;
    top: 0;
    font-size: 22px;
    color: #fff;
    margin-left: -600px;
    h4{
      line-height: 60px;
      text-align: left;
      margin-top: 40px;
    }
  }
  img{
    margin: 0 auto;
    width: 100%;
    height: 300px;
  }
  ::v-deep .el-carousel__item{
    display: flex;
    align-items: center;
  }
}

</style>
