<template>
  <div class="foot">
    <div class="foot-con btw mywidth">
      <div class="foot-nav row-l">
        <div
          class="nav-item column-l"
          v-for="(item, index) in cominfo.footnavs"
          :key="index"
        >
          <h3>{{ item.tit }}</h3>
          <span
            v-for="(xitem, xindex) in item.list"
            :key="xindex"
            @click="link(xitem.url)"
            >{{ xitem.name }}</span
          >
        </div>
      </div>
      <div class="erwm btw ">
        <div class="erwm-item column">
          <img src="@assets/img/foot-icon1.jpg" alt="" srcset="" />
          <p>电子招标投标系统<br />交易平台三星认证</p>
        </div>
        <div class="erwm-item column">
          <img src="@assets/img/qrcode.jpg" alt="" srcset="" />
          <p>关注易招云采<br />掌握更多商机</p>
        </div>
      </div>
    </div>
    <div class="copyright btw mywidth">
        <div class="copy-l" ><span @click="dzlink(cominfo.companyUrl)">{{cominfo.company}}</span> {{cominfo.icp}}</div>
        <div class="tell row">
          <img src="@assets/img/tell.png" alt="" srcset="" />
          <p>全国客户服务热线：<span>{{cominfo.tel}}</span> </p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
  props:['cominfo'],
  data() {
    return {

    };
  },
  methods: {
    link(val) {
       let path = this.$route.path+'?id='+this.$route.query.id;
      if (val && path != val) {
        this.$router.push(val);
      }
    },
    dzlink(val){
      window.open(val,'_blank')
    }
  },
};
</script>

<style lang="less" scoped>
.foot {
  background: @head-bg;
}
.foot-con {
  height:200px;
}
.nav-item {
  width: 180px;
}
.nav-item h3 {
  color: #666666;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 10px;
}
.nav-item span {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  line-height: 28px;
}
.nav-item span:hover {
  color: @bule;
}
.erwm {
  width: 270px;
  margin-right: 50px;
}
.erwm-item {
  img{
    width: 100px;
    height: 100px;
  }
  p {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-top: 10px;
}
}
.copyright{
  border-top: 1px solid #2f333b;
  color: #666666;
  font-size: 14px;
  line-height: 40px;
  padding-top: 5px;
}
.tell img{
  margin-right: 10px;
}
.tell span{
  font-size: 20px;
}
.copy-l span{
  cursor: pointer;
  &:hover{
    color: #888888;
  }
}
</style>
