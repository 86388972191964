<template>
  <div class="nav">
    <div class="mywidth row">
      <div class="logo">
        <img src="/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#20242b"
        text-color="#fff"
        active-text-color="#0096ff"
      >
      <template v-for="item of cominfo.hdtab" >
         <el-submenu   v-if="item.list" :key="item.id" :index="item.id" >
              <template slot="title">{{item.name}}</template>
              <el-menu-item v-for="xitem of item.list"  :key="xitem.id" :index="xitem.src" :route="xitem.src">
                {{xitem.name}}
                </el-menu-item>
           </el-submenu>
          <el-menu-item v-else :key="item.src"  :index="item.id"  :route="item.src">
          {{item.name}}
          </el-menu-item>
       </template>
      </el-menu>
      <div class="rx row">
        <span>客服热线</span>
        <h3>{{cominfo.tel}}</h3>
      </div>
    </div>
  </div>
</template>
<script>
// import { login } from "@/api/index.js";
export default {
  name: "Nav",
  props:['path','cominfo'],
  data() {
    return {
      activeIndex: "",
    };
  },
  watch:{
    path(val){
       this.activeIndex = val
    }
  },
  mounted() {
    
    if (window.performance.navigation.type == 1) {
      // console.log("页面被刷新");
      //  this.activeIndex = sessionStorage.getItem("key");
    } else {
     // console.log("首次被加载");
    }
  },
  created() {
    setTimeout(()=>{
        if(this.activeIndex==''){
        this.activeIndex='/'
        }
    },200)
    
   
  },
  methods: {
    handleSelect(key, keyPath, v) {
      let nowpath = v.route.split(':')[0]  
      if(nowpath=='http' || nowpath=='https'){
         window.open(v.route,'_blank')
         this.$router.go(0);
         return false
      }
       sessionStorage.setItem("key", key);
      let path = this.$route.path;
      if (v.route && path != v.route) {
        this.$router.push(v.route);
      }
       
    },
  },

  destroyed() {
    window.performance.navigation.type == 1;
  },
};
</script>
<style lang="less" scoped>
.nav {
  background:@head-bg;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  a{
    height:100% ;
    width: 100%;
    display: inline-block;
  }
}
.mywidth {
  position: relative;
  padding-top: 10px;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
  background: none;
}

::v-deep .el-menu--horizontal > .el-menu-item a,
.el-menu--horizontal > .el-menu-item a:hover {
  text-decoration: none;
}
.rx {
  position: absolute;
  right: 0;
  top: 10px;
  span {
    color: #c1e5ff;
    font-size: 14px;
  }
  h3 {
    color: @bule;
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
  }
}
.logo {
  margin-right: 28px;
  img{
    height: 50px;
  }
}

</style>
