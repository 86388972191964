<template>
  <div class="service">
    <div class="top column-cen">
      <img src="@assets/img/line-ren.png" alt="" srcset="" />
    </div>
    <div class="con">
      <div v-for="(item, index) in list" :key="index" @click="serherf(index)">
        <el-popover
          placement="left"
          trigger="hover"
          :ref="'popover' + index"
          v-if="index == 1 || index == 2 || index == 3"
        >
          <div class="dh" v-if="index == 1">
            <img class="ewm" :src="cominfo.qywxcode" alt="" srcset="" />
          </div>
          <div class="cpgw" v-if="index == 2 && qqList">
            <div class="row" v-for="(item,index) in qqList" :key="index" >
              <img class="gw" src="@assets/img/gw1.png" alt="" srcset="">
              <span>QQ: {{item}}</span>
            </div>
          </div>
          <div class="dh" v-if="index == 3">
             <div class="row" >
              <img class="yx" src="@assets/img/yx.png" alt="" srcset="">
              <span>{{cominfo.email}}</span>
            </div>
          </div>
        </el-popover>
        <div class="item column-cen" v-popover="'popover' + index">
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot",
   props:['cominfo'],
  data() {
    return {
      list: [
        { name: "在线咨询", img: require("@/assets/img/line-chat.png") },
        { name: "联系我们", img: require("@/assets/img/line-phone.png") },
        { name: "产品顾问", img: require("@/assets/img/line-weixin.png") },
        { name: "发送文件", img: require("@/assets/img/line-mail.png") },
        { name: "免费体验", img: require("@/assets/img/line-star.png") },
      ],
      show: "",
      qqList:[]
    };
  },
  watch:{
    cominfo(val){
      this.qqList =val && val.qqList? val.qqList.split(','):[]
    }
  },
  mounted(){
  },
  methods: {
    serherf(val) {
      this.show = val;
      let path = this.$route.path;
      // 客服
      if (val == 0) {
        if (path != "/service") {
          this.$router.push({
            path: "/service",
          });
        }
      }
      // 体验
      if (val == 4) {
        if (path != "/register") {
          this.$router.push({
            path: "/register",
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 1000;
  margin-top: -210px;
  // width: 400px;
  height: 420px;
  .top {
    position: absolute;
    top: 0;
    width: 50;
    z-index: 1;
    right: 12px;
    width: 60px;
  }
  .con {
    position: absolute;
    top: 50px;
    right: 0;
    background: #eefbff;
    width: 60px;
    padding: 10px 10px 2px;
    border: 2px solid #d0f3ff;
    border-radius: 5px;
    .item {
      background: #ffffff;
      border-radius: 5px;
      padding: 10px 0;
      margin-bottom: 8px;
      cursor: pointer;
      span {
        margin-top: 5px;
        font-size: 12px;
        color: #666666;
      }
      &:hover {
        box-shadow: 0 0 5px #99d5e5;
      }
    }
  }
  .del {
    position: absolute;
    right: 82px;
    top: 100px;
    background: #fff;
    padding: 15px;
    .delitem {
      font-size: 15px;
      line-height: 30px;
      color: @bule;
    }
  }
}
.cpgw{
  line-height:36px;
  cursor: pointer;
  color:@bule;
  .gw{
    width: 20px;
    margin-right: 10px;
  }
}
 .yx{
    width: 25px;
    margin-right: 10px;
  }
  .ewm{
    width: 130px;
  }

</style>
