<template>
  <div class="links  ">
       <div class="indtit">友情链接</div>
       <div class="linkscon mywidth row">
         <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links1.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links2.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links3.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links4.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links5.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links6.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links7.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links8.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links9.png" alt="">
         </div>
          <div class="links-item row-cen" @click="linksurl()">
             <img src="@assets/img/links10.png" alt="">
         </div>
         
       </div>
  </div>
</template>

<script>

export default {
  name: "links",
  data () {
    return {
      
    }
  },
  methods: {
    linksurl(e){
      console.log(e);
    }
  }

};
</script>

<style lang="less" scoped>

.linkscon{
  flex-wrap: wrap;
}
.links-item{
    width: 234px;
    height:65px;
    //  border: 1px solid #f9f9f9;
    cursor: pointer;
    margin-bottom: 15px;
    border-radius: 5px;
}
.links-item:hover{
  // border: 1px solid @bule;
  box-shadow: 0 0 10px @bule;
}
.links-item img{
  width: 160px;
}

</style>