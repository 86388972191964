import axios from 'axios'
import {	Message} from 'element-ui';
import com from './com' 
// 根据环境不同引入不同api地址

const service = axios.create({
  baseURL: com.baseURL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if ( res.code =='error' || res.code =='syserror') {
      Message(res.msg)
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
   
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
