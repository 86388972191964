<template>
  <div class="statute box-bg">
    <div class="tit bule-col">政策法规 </div>
  
    <div class="list btw" v-for="(item, index) in list" :key="index" @click="del(item.infoid)">
      <div class="title ellipsis-1">
        {{item.title}}
      </div>
      <div class="time">{{item.infodate}}</div>
    </div>
    <div class="more" @click="more">查看更多></div>
  </div>
</template>

<script>
import { statute } from "@/api/index.js";
export default {
  name: "statute",
  data() {
    return {
      list:[]
    };
  },
  mounted () {
    this.getstatute()
  },
  methods: {
     getstatute() {
      let params={}
      statute(params).then((data) => {
        this.list =  data.tdata
      });
    },
     // 详情
    del(val){
      this.$router.push({
          path: '/notice/del',
          query: {
            id: val,
          }
        });
    },
    more(){
      this.$router.push({
          path: '/notice',
        });
    }
   
  },
};
</script>

<style lang="less" scoped>

.tit {
  border-bottom: 1px solid @border-color;
  text-align: left;
    font-size: 15px;
  line-height: 40px;
  margin-bottom: 10px;
}

.list {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  .title{
    width: 645px;
    line-height: 28px;
  }
  &:hover{
    color: @bule;
    font-weight: bold;
  }
}
.more {
  text-align: right;
  color: @bule;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  margin-top: 10px;
}
</style>