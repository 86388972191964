import request from '@/utils/request'


// 获取首页公用信息
export function index(params) {
  return request({
    url: '/config/index.json',
    method: 'get',
    params
  })
}
//轮播图
export function getcarouselpic(params) {
  return request({
    url: 'web/home/headImage.json',
    method: 'get',
    params
  })
}
// 招标列表
export function jyinfo(params) {
  return request({
    url: 'web/home/announcementList.json',
    method: 'get',
    params
  })
}
// 政策法规
export function statute(params) {
  return request({
    url: 'web/home/noticeList.json',
    method: 'get',
    params
  })
}
// 相关下载
export function tooldownload() {
  return request({
    url: 'web/home/downloadResource.json',
    method: 'get',
  })
}
