<template>
  <div class="index">
    <my-ban></my-ban>
    <div class="con btw-l mywidth">
      <div class="conl">
        <conl-news :pagination="type=='buss'" :list='list' :loading='loading' :total='total' @page='getpage' @type='gettype' @sou='getsou'></conl-news>
        <img class="adv" v-if="cominfo.adList" @click="link(cominfo.adList[0].url)" :src="cominfo.adList[0].img" alt="" srcset="" />
        <conl-statute></conl-statute>
      </div>
      <my-conr :cominfo='cominfo'></my-conr>
    </div>
    <!-- <my-advant></my-advant> -->
    <my-links></my-links>
    <my-video></my-video>
  </div>
</template>
<script>
import { jyinfo } from "@/api/index.js";

import MyBan from "./com/my-ban";
// import MyAdvant from './com/my-advant' 优势
import MyLinks from "./com/my-links";
import ConlNews from "./com/con-news";
import ConlStatute from "./com/con-statute";
import MyConr from "./com/con-r";
import MyVideo from "./com/video";
import axios from 'axios'

export default {
  name: "Index",
  props:['cominfo'],
  components: {
    MyBan,
    //  MyAdvant,
    MyLinks,
    ConlNews,
    ConlStatute,
    MyConr,
    MyVideo
  },
  data() {
    return {
      tdata: [],
      type:'buss',
      hyparam: {
        type: "all",
        citycode: "",
        agent: "",
        status: "",
        title: "",
        maxNum: "10",
      },
      list: [],
      loading : true,
      bussdata:{
        pageNum:1,
        pageSize:10,
      },
      total:0,
    };
  },
  mounted() {
    this.getbuss()
  },
  methods: {
    link(val){
      window.open(val,'_blank')
    },
    getbuss(){
      this.loading = true
      let url = "http://analysis.ezyunc.com/prod-api/api/bidding/bussinessList"
    axios({
      method:"post",
      url:url,
      params: this.bussdata
        }).then(res=>{
         this.list = res.data.rows;
         this.loading = false
         this.total = res.data.total
      })
    },
    // 信息
    getjyinfo() {
      this.loading = true
      jyinfo(this.hyparam).then((res) => {
        this.list = res.tdata;
         this.loading = false
      });
    },
    // 选择类型
    gettype(val){
      this.type=val
      if(val=='buss'){
        this.bussdata.pageNum = 1
        this.getbuss()
      }else{
        this.hyparam.type = val
        this.getjyinfo()
      }
    },
    // 搜索
    getsou(val){
      this.hyparam.title = val
      this.getjyinfo()
    },
    //分页
    getpage(val){
      if(this.type=='buss'){
        this.bussdata.pageNum = val
        this.getbuss()
      }
     
    },
    open(val) {
      window.open(val, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.con {
  z-index: 10;
  margin-top: 20px;
  position: relative;
  .conl {
  width: 835px;
  }
  .adv {
    margin: 15px auto;
    cursor: pointer;
  }
}
</style>
