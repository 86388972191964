<template>
  <div id="app" :class="$route.meta.headshow?'':'noapp'">
     <my-nav :path='path' :cominfo='cominfo' v-if="$route.meta.headshow"></my-nav>
     <router-view :cominfo='cominfo' class="view"/>
     <my-foot :cominfo='cominfo' v-if="$route.meta.footshow"></my-foot>
     <my-service :cominfo='cominfo'  v-if="$route.meta.serviceshow!==false"></my-service>
  </div>
</template>
<script>

import MyNav from './components/my-nav'
import MyFoot from './components/my-foot'
import MyService from './components/my-service.vue'
import { index } from "@/api/index.js";
// import  indexjs  from "../public/config/index.json";
export default {
     name: 'Index',
     components:{
        MyNav,
        MyFoot,
        MyService
     },
     data () {
       return {
         path:'',
         cominfo:''
       }
     },
      // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      let newpath  = to.path.split('/')
      this.path = newpath.length>2?'/'+newpath[1]:to.path
      this.getindex()
    }
  },
  mounted() {
    this.getindex()
  },
  methods: {
      getindex() {
      let params = {
        s: Math.random(),
      };
       index(params).then((res) => {
        this.cominfo = res
      });
      // this.cominfo = indexjs
    },
  },

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 70px;
}
#app.noapp{
 padding-top: 0;
}
.view{
  min-height: 400px;
}


</style>

