import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// base
import './assets/css/base.css'
import com from '@/utils/com'
Vue.prototype.com = com

import less from 'less'
Vue.use(less)
// 动画
import animated from 'animate.css'
Vue.use(animated)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
