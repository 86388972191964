// 根据 process.env.NODE_ENV 切换不同的 baseURL  production正式环境
const isPro = process.env.NODE_ENV === 'production'
// 幻灯片追加前面的路径地址
const banurl = 'http://ezyunc.com'
// 登陆入口链接
const loginurl = [
    {
        name: '新用户注册',
        url: '/register',
        id:0
    },
    {
        name: '代理机构登录',
        url: 'http://agent.ezyunc.com/admin/login.do',
        id:1
    },
    {
        name: '投标人登录',
        url: 'http://tender.ezyunc.com/bidder/index.html#/login',
        id:2
    },
    {
        name: '评审专家登录',
        url: 'http://review.ezyunc.com/',
        id:3
    },
    {
        name: '投标人登录',
        url: 'http://bid.ezyunc.com/admin/login.do',
        id:4
    },
    {
        name: '招标代理登录',
        url: 'http://bid.ezyunc.com/admin/agent/login.do',
        id:5
    },
    {
        name: '注册招标代理登陆',
        url: 'http://agent.ezyunc.com/admin/agent/login',
        id:6
    },
    {
        name: '注册招标人登陆',
        url: 'http://agent.ezyunc.com/admin/tender/login',
        id:7
    },
]
// 获取验证码图片
const getcaptcha = isPro?'/web/reg/captcha.htm':'/api/web/reg/captcha.htm'

// 获取接口地址   'api'：vue.config.js中proxy设置的代理
const baseURL = isPro ? '/' : '/api/'

//获取当前时间
var formatNum = function (num) {
    return num < 10 ? "0" + num : num
}
function formatDate(){
    var year = new Date().getFullYear()
    var month = formatNum(new Date().getMonth() + 1)
    var day = formatNum(new Date().getDate())
    var hour = formatNum(new Date().getHours())
    var min = formatNum(new Date().getMinutes())
    var sec = formatNum(new Date().getSeconds())
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
}

export default {
    banurl,
    loginurl,
    getcaptcha,
    baseURL,
    formatDate
}
